import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Button, notification } from 'antd';
import apiClient from 'services/axios';
import commaNumber from 'comma-number';

const mapStateToProps = ({ user }) => ({
  user,
  role: user.role,
  step: user.step,
  id: user.id,
  agent: user.agent,
});

class PartnerDepositModal extends React.Component {
  state = {
    rate: '',
    balance: '',
    depositAmount: 0,
    chargeAmount: 0,
  };

  componentDidMount() {
    this.getInfo();
  }

  getInfo = () => {
    const { user } = this.props;
    apiClient.get(`/agents/${user.agent}`).then(res => {
      this.setState({
        rate: res.data.info.ia_coupon_rate,
        balance: res.data.info.ia_coupon_balance,
      });
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: commaNumber(e.target.value),
    });
    console.log(this.state);
  };

  depositChange = e => {
    const { rate } = this.state;
    this.setState({
      depositAmount: e.target.value,
      chargeAmount: commaNumber((e.target.value / (rate / 100)).toFixed(0)),
    });
  };

  onFinish = () => {
    const { depositAmount, name } = this.state;
    const { cancel, target } = this.props;
    apiClient
      .post('/transactions/agent/request/deposit', {
        amount: Number(depositAmount),
        name,
      })
      .then(res => {
        notification.success({
          message: res.data.message,
        });
        cancel(target);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const { visible, cancel, target } = this.props;
    const { rate, balance, chargeAmount } = this.state;
    const xxx = true;
    return (
      <Modal
        destroyOnClose={xxx}
        title="충전신청"
        visible={visible}
        width="400px"
        onCancel={() => cancel(target)}
        footer={[
          <Button key="back" onClick={() => cancel(target)}>
            취소
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="requestDeposit"
            htmlType="submit"
          >
            신청
          </Button>,
        ]}
      >
        <Form layout="vertical" id="requestDeposit" onFinish={this.onFinish}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                name="balance"
                label="쿠폰잔액"
                initialValue={commaNumber(balance)}
              >
                <Input addonAfter="원" readOnly />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item name="rate" label="쿠폰비율" initialValue={rate}>
                <Input addonAfter="%" readOnly />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="name"
            label="예금주"
            rules={[
              {
                required: true,
                message: '예금주를 입력해주세요.',
              },
            ]}
          >
            <Input name="name" onChange={e => this.handleChange(e)} />
          </Form.Item>
          <Form.Item name="chargeAmount" label="충전금액">
            <p className="text-right">{chargeAmount} 원</p>
          </Form.Item>
          <Form.Item
            name="depositAmount"
            label="입금금액"
            rules={[
              {
                required: true,
                message: '입금금액을 입력해주세요.',
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: '숫자만 입력해주세요.',
              },
            ]}
          >
            <Input
              name="depositAmount"
              onChange={e => this.depositChange(e)}
              addonAfter="원"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(PartnerDepositModal);
