import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { history } from 'index';
import * as firebase from 'services/firebase';
import * as jwt from 'services/jwt';
import actions from './actions';

notification.config({
  duration: 2,
});

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
};

export function* LOGIN({ payload }) {
  const { username, password } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { authProvider: autProviderName } = yield select(
    state => state.settings,
  );
  const success = yield call(
    mapAuthProviders[autProviderName].login,
    username,
    password,
  );
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
    yield history.push('/dashboard');
    notification.success({
      message: '로그인 성공',
      description: '로그인 되었습니다.',
    });
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { authProvider } = yield select(state => state.settings);
  const success = yield call(
    mapAuthProviders[authProvider].register,
    email,
    password,
    name,
  );
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
    yield history.push('/');
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    });
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const { authProvider } = yield select(state => state.settings);
  const response = yield call(mapAuthProviders[authProvider].currentAccount);
  if (response) {
    const { id, username, email, name, avatar, roles, step, agent, forceTsProc } = response;
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        username,
        email,
        avatar,
        roles,
        step,
        authorized: true,
        agent,
        forceTsProc
      },
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings);
  yield call(mapAuthProviders[authProvider].logout);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      agent: '',
      name: '',
      roles: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  });
  notification.success({
    message: '로그아웃 성공',
    description: '로그아웃 되었습니다.',
  });
  setTimeout(() => {
    window.location.reload();
  }, 500);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
