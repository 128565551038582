export const Bank = {
  banks: [
    {
      Key: 1,
      Value: '신한은행',
    },
    {
      Key: 2,
      Value: '국민은행',
    },
    {
      Key: 3,
      Value: 'KEB 하나은행',
    },
    {
      Key: 4,
      Value: '우리은행',
    },
    {
      Key: 5,
      Value: '케이뱅크',
    },
    {
      Key: 6,
      Value: '카카오뱅크',
    },
    {
      Key: 7,
      Value: 'KDB산업은행',
    },
    {
      Key: 8,
      Value: '기업은행',
    },
    {
      Key: 9,
      Value: '농협은행',
    },
    {
      Key: 10,
      Value: '수협은행',
    },
    {
      Key: 11,
      Value: '대구은행',
    },
    {
      Key: 12,
      Value: '부산은행',
    },
    {
      Key: 13,
      Value: '경남은행',
    },
    {
      Key: 14,
      Value: '광주은행',
    },
    {
      Key: 15,
      Value: '전북은행',
    },
    {
      Key: 16,
      Value: '제주은행',
    },
    {
      Key: 18,
      Value: '새마을금고',
    },
    {
      Key: 19,
      Value: '우체국',
    },
    {
      Key: 20,
      Value: '신협',
    },
    {
      Key: 21,
      Value: '산림조합',
    },
    {
      Key: 22,
      Value: 'HSBC은행',
    },
    {
      Key: 23,
      Value: '씨티은행',
    },
    {
      Key: 24,
      Value: 'SC제일은행',
    },
    {
      Key: 25,
      Value: '신한금융투자',
    },
    {
      Key: 26,
      Value: '하나금융투자',
    },
    {
      Key: 27,
      Value: 'DB금융투자',
    },
    {
      Key: 28,
      Value: '유안타증권',
    },
    {
      Key: 29,
      Value: '상호저축은행중앙회',
    },
    {
      Key: 30,
      Value: '미래에셋',
    },
    {
      Key: 31,
      Value: '카카오페이증권',
    },
    {
      Key: 32,
      Value: 'NH투자증권',
    },
    {
      Key: 33,
      Value: '한국투자증권',
    },
    {
      Key: 34,
      Value: '유진투자증권',
    },
    {
      Key: 35,
      Value: '토스뱅크',
    },
  ],
};
export default Bank;
