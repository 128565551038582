import React from 'react';
import style from './style.module.scss';

const Footer = () => {
  return (
    <div className={style.footer} />
  );
};

export default Footer;
