import axios from "axios";
import store from "store";
import { notification } from "antd";

const apiClient = axios.create({
   baseURL: "https://api.snbp-serverapi.com/v1/",
  // baseURL: "http://localhost:10030/v1/",
   //  baseURL: "https://api.kong789.com/v1/",
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
});

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get("accessToken");
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers["x-access-token"] = accessToken;
  }
  return request;
});

apiClient.interceptors.response.use(undefined, (error) => {
  // Errors handling
  const { response } = error;
  const { data, status } = response;
  if (status === 401) {
    notification.warning({
      message: "인증이 만료되었습니다.",
    });
    store.remove("accessToken");
    window.location.href = "#/auth/login";
  }

  if (status !== 401 && data && data.message !== "No token provided!!") {
    notification.warning({
      message: data.message,
    });
    // window.location.href = '/';
  }
});

export default apiClient;
