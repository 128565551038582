import React from "react";
import { Link } from "react-router-dom";
import apiClient from "services/axios";
import { connect } from "react-redux";
import commaNumber from "comma-number";
import store from "store";
import { Switch } from "antd";
import {
  SoundOutlined,
  NotificationOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import FavPages from "./FavPages";
import UserMenu from "./UserMenu";
import style from "./style.module.scss";

const mapStateToProps = ({ user }) => ({
  user,
  role: user.roles,
  step: user.step,
  id: user.id,
});

class TopBar extends React.Component {
  state = {
    loaded: false,
    coupon: "",
    deposit: "",
    register: "",
    withdrawal: "",
    withdrawalCheck: "",
    balance: "",
    calcBalance: "",
    allSound: store.get("allSound"),
    regSound: store.get("regSound"),
    depositSound: store.get("depositSound"),
    // conindepositSound: store.get("coindepositSound"),
    // vbankdepositSound: store.get("vbankdepositSound"),
    withdrawSound: store.get("withdrawSound"),
    withdrawCheckSound: store.get("withdrawCheckSound"),
    withdrawRequestSound: store.get("withdrawRequestSound"),
    couponSound: store.get("couponSound"),
    certiSound: store.get("certiSound"),
    smsBalance: "",
    smsLoaded: true,
  };

  componentDidMount() {
    const { step } = this.props;
    this.getInfo();
    if (step < 3) {
      this.getAlert();
      this.getSmsBalance();
      this.getSwixBalance();
      setInterval(() => {
        this.getAlert();
      }, 5000);
    }
  }

  couponStart = () => {
    if (store.get("couponSound") === true) {
      new Audio("/resources/sounds/coupon.mp3").play();
    }
  };

  depositStart = () => {
    if (store.get("depositSound") === true) {
      new Audio("/resources/sounds/deposit.mp3").play();
    }
  };

  coindepositStart = () => {
    if (store.get("coindepositSound") === true) {
      new Audio("/resources/sounds/deposit.mp3").play();
    }
  };

  vbankdepositStart = () => {
    if (store.get("vbankdepositSound") === true) {
      new Audio("/resources/sounds/vbankdeposit.mp3").play();
    }
  };

  withdrawStart = () => {
    if (store.get("withdrawSound") === true) {
      new Audio("/resources/sounds/withdraw.mp3").play();
    }
  };

  withdrawCheckStart = () => {
    if (store.get("withdrawCheckSound") === true) {
      new Audio("/resources/sounds/withdrawcheck.mp3").play();
    }
  };

  withdrawRequestStart = () => {
    if (store.get("withdrawRequestSound") === true) {
      new Audio("/resources/sounds/withdrawrequest.mp3").play();
    }
  };

  registerStart = () => {
    if (store.get("regSound") === true) {
      new Audio("/resources/sounds/register.mp3").play();
    }
  };

  certificationStart = () => {
    if (store.get("certiSound") === true) {
      new Audio("/resources/sounds/certification.mp3").play();
    }
  };

  getAlert = () => {
    apiClient.get("/alerts").then((res) => {
      this.setState({
        coupon: res.data.coupon,
        deposit: res.data.deposit,
        // vbankdeposit: res.data.vbankdeposit,
        register: res.data.register,
        withdrawal: res.data.withdrawal,
        withdrawalCheck: res.data.withdrawalCheck,
        certification: res.data.certification,
      });

      if (res.data.coupon > 0 && store.get("couponSound") === true) {
        this.couponStart();
      }

      // if (res.data.deposit > 0 && store.get("depositSound") === true) {
      //   if (res.data.vbankdeposit > 0 && store.get("vbankdepositSound") === true) {
      //     this.vbankdepositStart();
      //   } else if (store.get("coindepositSound") === true) {
      //      this.depositStart();
      //   }
      // }

      if (res.data.deposit > 0 && store.get("depositSound") === true) {
        this.depositStart();
      }

      if (res.data.withdrawal > 0 && store.get("withdrawSound") === true) {
        this.withdrawStart();
      }

      if (res.data.withdrawalCheck > 0 && store.get("withdrawCheckSound") === true) {
        this.withdrawCheckStart();
      }

      if (res.data.withdrawal > 0 && store.get("withdrawRequestSound") === true) {
        this.withdrawRequestStart();
      }

      if (res.data.register > 0 && store.get("regSound") === true) {
        this.registerStart();
      }

      if (res.data.certification > 0 && store.get("certiSound") === true) {
        this.certificationStart();
      }
    });
  };

  soundChange = (type, value) => {
    store.set(type, value);
    this.setState({
      [type]: value,
    });

    if (type === "allSound") {
      store.set("regSound", value);
      store.set("depositSound", value);
      store.set("coindepositSound", value);
      store.set("vbankdepositSound", value);
      store.set("withdrawSound", value);
      store.set("withdrawCheckSound", value);
      store.set("withdrawRequestSound", value);
      store.set("couponSound", value);
      store.set("certiSound", value);

      this.setState({
        regSound: value,
        depositSound: value,
        coindepositSound: value,
        vbankdepositSound: value,
        withdrawCheckSound: value,
        withdrawRequestSound: value,
        couponSound: value,
        certiSound: value,
      });
    }

    if (type === "regSound" && value === false) {
      new Audio("/resources/sounds/register.mp3").pause();
    } else if (type === "depositSound" && value === false) {
      new Audio("/resources/sounds/deposit.mp3").pause();
    } else if (type === "withdrawSound" && value === false) {
      new Audio("/resources/sounds/withdraw.mp3").pause();
    } else if (type === "withdrawCheckSound" && value === false) {
      new Audio("/resources/sounds/withdrawcheck.mp3").pause();
    } else if (type === "withdrawRequestSound" && value === false) {
      new Audio("/resources/sounds/withdrawrequest.mp3").pause();
    } else if (type === "couponSound" && value === false) {
      new Audio("/resources/sounds/coupon.mp3").pause();
    } else if (type === "certiSound" && value === false) {
      new Audio("/resources/sounds/certification.mp3").pause();
    }
  };

  getInfo = () => {
    this.setState({
      loaded: false,
    });
    const { user } = this.props;
    apiClient.get(`/agents/${user.agent}`).then((res) => {
      this.setState({
        balance: res.data.info.ia_coupon_balance,
        calcBalance: res.data.info.ia_calc_balance,
        loaded: true,
      });
    });
  };

  linkClick = (url) => {
    const index = window.location.href.indexOf("#");
    if (window.location.href.substr(index) === `#${url}`) {
      window.location.reload();
    }
  };

  getSwixBalance = () => {
    this.setState({
      swixBalanceLoaded: false,
    });

    apiClient.get(`/xtreem/balance`).then((res) => {
      this.setState({
        swixBalance: res.data.balance,
        swixBalanceLoaded: true,
      });
    });
  };

  getSmsBalance = () => {
    this.setState({
      loaded: false,
    });
    apiClient.get("/agents/sms/balance").then((res) => {
      this.setState({
        smsBalance: res.data.balance,
        smsLoaded: true,
      });
    });
  };

  render() {
    const {
      coupon,
      deposit,
      withdrawal,
      withdrawalCheck,
      certification,
      register,
      regSound,
      depositSound,
      // coindepositSound,
      // vbankdepositSound,
      withdrawSound,
      withdrawCheckSound,
      withdrawRequestSound,
      couponSound,
      certiSound,
      allSound,
      balance,
      calcBalance,
      loaded,
      smsBalance,
      smsLoaded,
      swixBalanceLoaded,
      swixBalance,
    } = this.state;
    const { step } = this.props;
    return (
      <div className={style.topbar}>
        <div className="mr-4">
          <FavPages />
        </div>
        <div className="mr-4">
          쿠폰잔액: {loaded ? commaNumber(balance) : <LoadingOutlined />} 원
        </div>
        {step < 2 && (
          <>
            <div className="mr-4">
              영상사 잔액:{" "}
              {swixBalanceLoaded ? (
                commaNumber(Number(swixBalance))
              ) : (
                <LoadingOutlined />
              )}{" "}
              원
            </div>
            <div className="mr-auto">
              SMS잔액:{" "}
              {smsLoaded ? commaNumber(smsBalance) : <LoadingOutlined />} (
              {smsLoaded ? commaNumber(smsBalance / 0.04) : <LoadingOutlined />}{" "}
              건)
            </div>
          </>
        )}
        {step > 1 && (
          <div className="mr-auto">
            정산가능금액:{" "}
            {loaded ? commaNumber(calcBalance) : <LoadingOutlined />} 원
          </div>
        )}
        {step === 2 && (
          <>
            {allSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}
            <Switch
              defaultChecked={store.get("allSound")}
              className="mr-2"
              onChange={(value) => this.soundChange("allSound", value)}
            />
            <div className="mr-4 d-none d-block">알림</div>
            {regSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}
            <Switch
              defaultChecked={store.get("regSound")}
              checked={regSound}
              className="mr-2"
              onChange={(value) => this.soundChange("regSound", value)}
            />
            <Link
              to="/users/status/new"
              onClick={() => this.linkClick("/users/status/new")}
            >
              <div className="mr-4 d-none d-block">회원가입 ({register})</div>
            </Link>
            {depositSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}
            <Switch
              defaultChecked={store.get("depositSound")}
              checked={depositSound}
              className="mr-2"
              onChange={(value) => this.soundChange("depositSound", value)}
            />
            <Link
              to="/transactions/deposit"
              onClick={() => this.linkClick("/transactions/deposit")}
            >
              <div className="mr-4 d-none d-block">입금신청 ({deposit})</div>
            </Link>


            {/* {depositSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}
            <Switch
              defaultChecked={store.get("coindepositSound")}
              checked={coindepositSound}
              className="mr-2"
              onChange={(value) => this.soundChange("coindepositSound", value)}
            />
            <Link
              to="/transactions/deposit"
              onClick={() => this.linkClick("/transactions/deposit")}
            >
              <div className="mr-4 d-none d-block">코인입금 ({deposit})</div>
            </Link>
            {depositSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}

            <Switch
              defaultChecked={store.get("vbankdepositSound")}
              checked={vbankdepositSound}
              className="mr-2"
              onChange={(value) => this.soundChange("vbankdepositSound", value)}
            />
            <Link
              to="/transactions/deposit"
              onClick={() => this.linkClick("/transactions/deposit")}
            >
              <div className="mr-4 d-none d-block">가상입금 ({deposit})</div>
            </Link> */}


            {withdrawSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}
            <Switch
              defaultChecked={store.get("withdrawSound")}
              checked={withdrawSound}
              className="mr-2"
              onChange={(value) => this.soundChange("withdrawSound", value)}
            />
            <Link
              to="/transactions/withdrawal"
              onClick={() => this.linkClick("/transactions/withdrawal")}
            >
              <div className="mr-4 d-none d-block">전체출금목록 ({withdrawal})</div>
            </Link>


            {withdrawRequestSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}
            <Switch
              defaultChecked={store.get("withdrawRequestSound")}
              checked={withdrawRequestSound}
              className="mr-2"
              onChange={(value) => this.soundChange("withdrawRequestSound", value)}
            />

            {withdrawRequestSound ? (
              <Link
                to="/transactionrequest/withdrawal"
                onClick={() => this.linkClick("/transactionrequest/withdrawal")}
              >
                <div className="mr-4 d-none d-block">윈로스확인대기 ({withdrawal})</div>
              </Link>
            ) : (
              <div className="mr-4 d-none d-block">윈로스확인대기 ({withdrawal})</div>
            )}


            {withdrawCheckSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}

            <Switch
              defaultChecked={store.get("withdrawCheckSound")}
              checked={withdrawCheckSound}
              className="mr-2"
              onChange={(value) => this.soundChange("withdrawCheckSound", value)}
            />
            {withdrawCheckSound ? (
              <Link
                to="/transactioncheck/withdrawal"
                onClick={() => this.linkClick("/transactioncheck/withdrawal")}
              >
                <div className="mr-4 d-none d-block">윈로스확인완료 ({withdrawalCheck})</div>
              </Link>
            ) : (
              <div className="mr-4 d-none d-block">윈로스확인완료 ({withdrawalCheck})</div>
            )}



            {couponSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}
            <Switch
              defaultChecked={store.get("couponSound")}
              checked={couponSound}
              className="mr-2"
              onChange={(value) => this.soundChange("couponSound", value)}
            />
            <Link
              to="/coupons/request"
              onClick={() => this.linkClick("/coupons/request")}
            >
              <div className="mr-4 d-none d-block">쿠폰신청 ({coupon})</div>
            </Link>
            {certiSound ? (
              <SoundOutlined className="mr-2" />
            ) : (
              <NotificationOutlined className="mr-2" />
            )}
            <Switch
              defaultChecked={store.get("certiSound")}
              checked={certiSound}
              className="mr-2"
              onChange={(value) => this.soundChange("certiSound", value)}
            />
            <Link
              to="/certification"
              onClick={() => this.linkClick("/certification")}
            >
              <div className="mr-4 d-none d-block">
                인증요청 ({certification})
              </div>
            </Link>
          </>
        )}
        {/* <div className="mr-4 d-none d-sm-block">
          <LanguageSwitcher />
        </div>
        <div className="mr-4 d-none d-sm-block">
          <Actions />
        </div> */}
        <div className="">
          <UserMenu />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(TopBar);

