import actions from './actions';

const initialState = {
  id: '',
  agent: '',
  name: '',
  role: '',
  step: '',
  username: '',
  avatar: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  forceTsProc: ''
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
