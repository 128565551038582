import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import classNames from 'classnames';
import store from 'store';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { find } from 'lodash';
import ModalStation from 'components/modalStation';
import style from './style.module.scss';

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.roles,
});

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },

  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
  role,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(
    store.get('app.menu.selectedKeys') || [],
  );
  const [openedKeys, setOpenedKeys] = useState(
    store.get('app.menu.openedKeys') || [],
  );

  const [isModalVisible, setIsModalVisible] = useState({});
  const modals = [];

  const showModal = e => {
    setIsModalVisible(prev => ({ ...prev, [e]: true }));
  };

  // const handleOk = e => {
  //   setIsModalVisible(prev => ({ ...prev, [e]: false }));
  // };

  const handleCancel = e => {
    setIsModalVisible(prev => ({ ...prev, [e]: false }));
  };

  useEffect(() => {
    applySelectedKeys();

    if (modals.length > 0) {
      modals.forEach(x => {
        setIsModalVisible(prev => ({ ...prev, [x]: false }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData]);

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);
    const selectedItem = find(flattenItems(menuData, 'children'), [
      'url',
      pathname,
    ]);
    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  };

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return;
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    });
    setOpenedKeys([]);
  };

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys);
    setOpenedKeys(keys);
  };

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key]);
    setSelectedKeys([e.key]);
  };

  const linkClick = (url) => {
    const index = window.location.href.indexOf('#')
    if(window.location.href.substr(index) === `#${url}`) {
      window.location.reload()
    }
  }

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, icon, disabled, count, target } = item;
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />;
      }
      if (item.url && !item.is_modal) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && (
                  <span className="badge badge-success ml-2">{count}</span>
                )}
                {icon && (
                  <span
                    className={`${icon} ${style.icon} icon-collapsed-hidden`}
                  />
                )}
              </a>
            )}
            {!item.target && (
              <Link to={url} onClick={() => linkClick(url)}>
                <span className={style.title}>{title}</span>
                {count && (
                  <span className="badge badge-success ml-2">{count}</span>
                )}
                {icon && (
                  <span
                    className={`${icon} ${style.icon} icon-collapsed-hidden`}
                  />
                )}
              </Link>
            )}
          </Menu.Item>
        );
      }

      if (item.is_modal) {
        modals.push(item.target);
        return (
          <Menu.Item key={key} disabled={disabled}>
            <ModalStation
              visible={isModalVisible[item.target]}
              target={item.target}
              cancel={handleCancel}
            />
            <a
              className={style.title}
              href="#"
              onClick={e => {
                e.preventDefault();
                showModal(item.target);
              }}
              target={target}
            >
              {title}
            </a>
            {count && <span className="badge badge-success ml-2">{count}</span>}
            {icon && (
              <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />
            )}
          </Menu.Item>
        );
      }

      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && (
            <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />
          )}
        </Menu.Item>
      );
    };

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.roles && !menuItem.roles.includes(role)) {
          return null;
        }
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && (
                <span className="badge badge-success ml-2">
                  {menuItem.count}
                </span>
              )}
              {menuItem.icon && (
                <span className={`${menuItem.icon} ${style.icon}`} />
              )}
            </span>
          );
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          );
        }
        return generateItem(menuItem);
      });

    return menuData.map(menuItem => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null;
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && (
              <span className="badge badge-success ml-2">{menuItem.count}</span>
            )}
            {menuItem.icon && (
              <span className={`${menuItem.icon} ${style.icon}`} />
            )}
          </span>
        );
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }
      return generateItem(menuItem);
    });
  };

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      };

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.ygreen]: menuColor === 'ygreen',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height:
            isMobileView || isMenuUnfixed
              ? 'calc(100% - 64px)'
              : 'calc(100% - 110px)',
        }}
      >
        <Link to="/dashboard">
          <div className={style.logoContainer}>
            <div className={style.logo}>
              <img
                src="resources/images/logo.svg"
                className="mr-2"
                alt="BAKCOFFICE"
              />
              <div className={style.name}>{logo}</div>
            </div>
          </div>
        </Link>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  );
};

export default withRouter(connect(mapStateToProps)(MenuLeft));
