import React from 'react';
import { Modal, Form, Select, Spin, Input, Button, notification } from 'antd';
import debounce from 'lodash/debounce';
import apiClient from 'services/axios';
// import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import JoditEditor from "jodit-react";
// import draftToHtml from 'draftjs-to-html';

const { Option } = Select;

class NoticeModal extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 10);
  }

  state = {
    data: [],
    value: [],
    fetching: false,
    title: '',
    content: '',
    type: '회원검색',
    inputDisable: false,
    inputRequire: true,
    // editorState: EditorState.createEmpty(),
  };

  selectChange = value => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // onEditorStateChange = editorState => {
  //   this.setState({
  //     editorState,
  //   });
  // };

  fetchUser = value => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    apiClient.get(`/users/search?s=${value}`).then(body => {
      if (body.data.length > 0) {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        const data = body.data.map(user => ({
          text: `${user.iu_name} ${user.iu_username}`,
          value: user.iu_uuid,
        }));
        this.setState({ data, fetching: false });
      } else {
        this.setState({ data: [], fetching: false });
      }
    });
  };

  onFinish = () => {
    const { title, value, type, content } = this.state;
    const { cancel, target, user, uuid } = this.props;
    
    if (user) {
      apiClient
        .post('/notifications', {
          uuid,
          title,
          // content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
          //   .replaceAll('&lt;', '<')
          //   .replaceAll('&gt;', '>')
          //   .replaceAll('nbsp;', ' ')
          //   .replaceAll('&amp;', '&')
          //   .replaceAll('<p></p>', '<br>')
          //   .replaceAll('<p style="text-align:center;"></p>', '<br>'),
          content: content.replaceAll('background-color', '')
        })
        .then(res => {
          notification.success({
            message: res.data.message,
          });
          cancel(target);
        })
        .catch(e => {
          console.log(e);
        });
    }
    if (type === '전체회원') {
      apiClient
        .post('/notifications', {
          uuid: 'all',
          title,
          // content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
          //   .replaceAll('&lt;', '<')
          //   .replaceAll('&gt;', '>')
          //   .replaceAll('nbsp;', ' ')
          //   .replaceAll('&amp;', '&')
          //   .replaceAll('<p></p>', '<br>')
          //   .replaceAll('<p style="text-align:center;"></p>', '<br>'),
          content: content.replaceAll('background-color', '')
        })
        .then(res => {
          notification.success({
            message: res.data.message,
          });
          cancel(target);
        })
        .catch(e => {
          console.log(e);
        });
    } else if (type === '회원검색') {
      for (let i = 0; i < value.length; i += 1) {
        apiClient
          .post('/notifications', {
            uuid: value[i].key,
            title,
            content: content.replaceAll('background-color', '')
          })
          .then(res => {
            notification.success({
              message: res.data.message,
            });
            cancel(target);
          })
          .catch(e => {
            console.log(e);
          });
      }
    } else if (type === '영상권한') {
        apiClient
        .post('/notifications', {
          uuid: '영상권한',
          value: value.key,
          title,
          content: content.replaceAll('background-color', '')
        })
        .then(res => {
          notification.success({
            message: res.data.message,
          });
          cancel(target);
        })
        .catch(e => {
          console.log(e);
        });
    } else if (type === '코인타입') {
      apiClient
      .post('/notifications', {
        uuid: '코인타입',
        value: value.key,
        title,
        content: content.replaceAll('background-color', '')
      })
      .then(res => {
        notification.success({
          message: res.data.message,
        });
        cancel(target);
      })
      .catch(e => {
        console.log(e);
      });
  }
  };

  typeChange = value => {
    this.setState({
      type: value,
    });

    if (value === '전체회원') {
      this.setState({
        inputDisable: true,
        inputRequire: false,
      });
    } else if (value === '회원검색') {
      this.setState({
        inputDisable: false,
        inputRequire: true,
      });
    }
  };
  
  editorChange = value => {
    this.setState({
      content: value
    })
  }

  render() {
    const { visible, cancel, target, user } = this.props;
    const {
      fetching,
      data,
      value,
      inputDisable,
      inputRequire,
      // editorState,
      type
    } = this.state;
    const xxx = true;
    if (user) {
      return (
        <Modal
          destroyOnClose={xxx}
          title="알림 전송"
          visible={visible}
          width="400px"
          onCancel={() => cancel(target)}
          footer={[
            <Button key="back" onClick={() => cancel(target)}>
              취소
            </Button>,
            <Button
              key="submit"
              type="primary"
              form="sendNotice"
              htmlType="submit"
            >
              전송
            </Button>,
          ]}
        >
          <Form layout="vertical" id="sendNotice" onFinish={this.onFinish}>
            <Form.Item
              label="회원"
              name="search"
              initialValue={`${user}`}
              rules={[
                {
                  required: true,
                  message: '회원을 선택해주세요.',
                },
              ]}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="title"
              label="제목"
              rules={[
                {
                  required: true,
                  message: '제목을 입력해주세요.',
                },
              ]}
            >
              <Input
                name="title"
                placeholder="제목"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="content"
              label="내용"
              rules={[
                {
                  required: true,
                  message: '내용을 입력해주세요.',
                },
              ]}
            >
              {/* <JoditEditor
                editorState={editorState}
                editorClassName="px-3 border border-gray-1"
                editorStyle={{
                  height: 250,
                  overflow: 'auto',
                }}
                onEditorStateChange={this.onEditorStateChange}
              /> */}
              <JoditEditor
                onChange={this.editorChange}
              />
            </Form.Item>
          </Form>
        </Modal>
      );
    }
    return (
      <Modal
        destroyOnClose={xxx}
        title="알림전송"
        visible={visible}
        width="400px"
        onCancel={() => cancel(target)}
        footer={[
          <Button key="back" onClick={() => cancel(target)}>
            취소
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="sendNotice"
            htmlType="submit"
          >
            전송
          </Button>,
        ]}
      >
        <Form layout="vertical" id="sendNotice" onFinish={this.onFinish}>
          <Form.Item
            label="회원선택"
            rules={[
              {
                required: true,
                message: '회원을 선택해주세요.',
              },
            ]}
          >
            <Select
              defaultValue="회원검색"
              onChange={this.typeChange}
              style={{ width: '100%' }}
            >
              <Option value="회원검색">회원검색</Option>
              <Option value="전체회원">전체회원</Option>
              <Option value="영상권한">영상권한선택</Option>
              <Option value="코인타입">코인타입</Option>
            </Select>
          </Form.Item>
          {type === '회원검색' && 
            <Form.Item
              label="회원검색"
              name="search"
              rules={[
                {
                  required: inputRequire,
                  message: '회원을 선택해주세요.',
                },
              ]}
            >
              <Select
                mode="multiple"
                labelInValue
                disabled={inputDisable}
                value={value}
                placeholder="회원을 검색해주세요. (두 글자 이상 입력해주세요.)"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.fetchUser}
                onChange={this.selectChange}
                style={{ width: '100%' }}
              >
                {data.map(d => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
            </Form.Item>
          }
          {type === '영상권한' && 
          <Form.Item
            label="영상권한 타입"
            name="search"
            rules={[
              {
                required: inputRequire,
                message: '영상권한 타입을 선택해주세요.',
              },
            ]}
          >
            <Select
              labelInValue
              value={value}
              placeholder="'영상권한 타입을 선택해주세요."
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onChange={this.selectChange}
              style={{ width: '100%' }}
            >
              <Option value="A">A</Option>
              <Option value="B">B</Option>
            </Select>
          </Form.Item>}
          {type === '코인타입' && 
          <Form.Item
            label="코인타입"
            name="search"
            rules={[
              {
                required: inputRequire,
                message: '코인타입을 선택해주세요.',
              },
            ]}
          >
            <Select
              labelInValue
              value={value}
              placeholder="코인타입을 선택해주세요."
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onChange={this.selectChange}
              style={{ width: '100%' }}
            >
              <Option value="1">코인1</Option>
              <Option value="2">코인2</Option>
              <Option value="3">코인3</Option>
            </Select>
          </Form.Item>}
          <Form.Item
            name="title"
            label="제목"
            rules={[
              {
                required: true,
                message: '제목을 입력해주세요.',
              },
            ]}
          >
            <Input
              name="title"
              placeholder="제목"
              onChange={this.handleChange}
            />
          </Form.Item>
          <Form.Item
            name="content"
            label="내용"
            rules={[
              {
                required: true,
                message: '내용을 입력해주세요.',
              },
            ]}
          >
            {/* <JoditEditor
              editorState={editorState}
              editorClassName="px-3 border border-gray-1"
              editorStyle={{
                height: 250,
                overflow: 'auto',
              }}
              onEditorStateChange={this.onEditorStateChange}
            /> */}
            <JoditEditor
              onChange={this.editorChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default NoticeModal;
