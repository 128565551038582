export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Dashboards',
    },
    {
      title: '대시보드',
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/dashboard',
    },
    {
      category: true,
      title: 'Users',
    },
    {
      title: '회원목록',
      key: 'users',
      icon: 'fe fe-users',
      children: [
        {
          title: '전체 회원목록',
          key: 'allUsers',
          url: '/users/status/all',
        },
        {
          title: '신규 회원목록',
          key: 'newUsers',
          url: '/users/status/new',
        },
        {
          title: '정상 회원목록',
          key: 'normalUsers',
          url: '/users/status/normal',
        },

        {
          title: '정지 회원목록',
          key: 'blockUsers',
          url: '/users/status/block',
        },
        {
          title: '탈퇴 회원목록',
          key: 'deleteUsers',
          url: '/users/status/delete',
        },
      ],
    },
    {
      title: '회원관리',
      key: 'manageUsers',
      icon: 'fe fe-user',
      roles: ['admin', 'master'],
      children: [
        {
          title: '회원등록',
          key: 'createUser',
          url: '/users/create',
        },
        {
          title: '인증문자내역',
          key: 'snsList',
          url: '/verification',
        },
        {
          title: '알림전송',
          key: 'sendNotification',
          target: 'notice_modal',
          url: '#',
          is_modal: true,
        },
        {
          title: 'N 알림전송 (beta)',
          key: 'sendNotification2',
          target: 'noticeauto_modal',
          url: '#',
          is_modal: true,
        },
        {
          title: '알림전송내역',
          key: 'notificationList',
          url: '/notificationlist',
        },
        {
          title: '알림전송 템플릿',
          key: 'notificationTempList',
          url: '/notificationtemplist',
        },
        {
          title: '인증요청내역',
          key: 'certificationList',
          url: '/certification',
        },
        {
          title: '유저계좌조회내역',
          key: 'userbankacccheckList',
          url: '/userbankaccchecklist',
        },
      ],
    },
    {
      category: true,
      title: 'Transactions',
    },
    {
      title: '입출금관리',
      key: 'transaction',
      icon: 'fe fe-dollar-sign',
      children: [
        {
          title: '입/출금 신청목록',
          key: 'transactionList',
          url: '/transactions/all',
        },
        {
          title: '입금 신청목록',
          key: 'depositList',
          url: '/transactions/deposit',
        },
        {
          title: '전체 출금 목록',
          key: 'withdrawList',
          url: '/transactions/withdrawal',
        },
        {
          title: '출금 요청목록',
          key: 'withdrawRequestkList',
          url: '/transactionrequest/withdrawal',
        },
        {
          title: '출금 검수완료',
          key: 'withdrawCheckList',
          url: '/transactioncheck/withdrawal',
        },
        {
          title: '쿠폰 신청목록',
          key: 'applyCouponList',
          url: '/coupons/request',
        },
      ],
    },
    {
      category: true,
      title: 'Coupons',
    },
    {
      title: '쿠폰관리',
      key: 'coupons',
      icon: 'fe fe-credit-card',
      children: [
        {
          title: '발급 쿠폰목록',
          key: 'sendCouponList',
          url: '/coupons',
        },
        {
          title: '쿠폰명 등록목록',
          key: 'couponNameList',
          url: '/coupons/name',
        },
        {
          title: '가입감사 쿠폰발급',
          key: 'sendCouponMember',
          target: 'coupon_modal',
          url: '#',
          is_modal: true,
        },
        {
          title: '자동승인 쿠폰발급',
          key: 'sendAutoCouponMember',
          target: 'auto_coupon_modal',
          url: '#',
          is_modal: true,
        },
        {
          title: '당일 올인 쿠폰발급',
          key: 'sendDailyAllinCoupon',
          url: '/allin',
        },
        {
          title: '주간 올인 쿠폰발급',
          key: 'sendWeeklyAllinCoupon',
          url: '/allinweekly',
        },
        {
          title: '주 활동 쿠폰발급',
          key: 'sendWeekCoupon',
          url: '/activity',
        },
      ],
    },
    {
      category: true,
      title: 'Partners',
    },
    {
      title: '파트너관리',
      key: 'partners',
      icon: 'fa fa-universal-access',
      children: [
        {
          title: '전체 파트너목록',
          key: 'partnerList',
          url: '/partner/list',
        },
        {
          title: '파트너 머니로그',
          key: 'partnerMoneyList',
          url: '/partner/moneylist',
        },
      ],
    },
    {
      title: '파트너 입출금 관리',
      key: 'partnerTransactions',
      icon: 'fa fa-won',
      children: [
        {
          title: '파트너 입/출금 신청목록',
          key: 'partnerTransactionList',
          url: '/transactions/partner/all',
        },
        {
          title: '파트너 입금 신청목록',
          key: 'partnerDepositList',
          url: '/transactions/partner/deposit',
        },
        {
          title: '파트너 출금 신청목록',
          key: 'partnerWithdrawList',
          url: '/transactions/partner/withdrawal',
        },
        {
          title: '충전신청',
          key: 'partnerdepositrequest',
          target: 'partnerDeposit_modal',
          url: '#',
          is_modal: true,
        },
        {
          title: '출금신청',
          key: 'partnerwithdrawalrequest',
          target: 'partnerWithdrawal_modal',
          url: '#',
          is_modal: true,
        },
      ],
    },
    {
      title: '파트너 정산관리',
      key: 'partnerCalculates',
      icon: 'fa fa-calculator',
      children: [
        {
          title: '파트너 일 단위 정산데이터',
          key: 'partnerDayCalculates',
          url: '/calculate/day',
        },
        {
          title: '파트너 주 단위 정산데이터',
          key: 'partnerWeekCalculates',
          url: '/calculate/week',
        },
        {
          title: '파트너 월 단위 정산데이터',
          key: 'partnerMonthCalculates',
          url: '/calculate/month',
        },
      ],
    },
    {
      category: true,
      title: 'Statistics',
    },
    {
      title: '종합통계',
      key: 'statistics',
      icon: 'fe fe-trending-up',
      children: [
        {
          title: '회원별 종합통계',
          key: 'memberStatistics',
          url: '/statistic/member',
        },
        {
          title: '게임사별 종합통계',
          key: 'gameStatistics',
          url: '/statistic/game',
        },
      ],
    },
    {
      category: true,
      title: 'History',
    },
    {
      title: '배팅기록',
      key: 'bettingHistory',
      icon: 'fa fa-history',
      children: [
        {
          title: '라이브카지노 배팅내역',
          key: 'liveBettingHistory',
          url: '/history/live',
        },
        {
          title: '호텔카지노 배팅내역',
          key: 'hotelBettingHistory',
          url: '/history/hotel',
        },
        {
          title: '슬롯게임 배팅내역',
          key: 'slotBettingHistory',
          url: '/history/slot',
        },
      ],
    },
    {
      category: true,
      title: 'Management',
    },
    {
      title: '게시판관리',
      key: 'boards',
      icon: 'fe fe-clipboard',
      roles: ['admin', 'master'],
      children: [
        {
          title: '전체 게시글목록',
          key: 'boardList',
          url: '/boards',
        },
        {
          title: '게시글 등록',
          key: 'boardRegister',
          url: '/board/write',
        },
      ],
    },
    {
      title: '도메인관리',
      key: 'domains',
      icon: 'icmn-IE',
      children: [
        {
          title: '전체 도메인 목록',
          key: 'domainList',
          url: '/domains/all',
        },
        {
          title: '도메인 신청목록',
          key: 'domainRegisterList',
          url: '/domains/request',
        },
        {
          title: '도메인 신청',
          key: 'domainRegister',
          target: 'domain_modal',
          url: '#',
          is_modal: true,
        },
      ],
    },
    {
      title: '카지노 게임관리',
      key: 'casinoGames',
      icon: 'fa fa-desktop',
      roles: ['admin', 'master'],
      children: [
        {
          title: '카지노게임 관리',
          key: 'casino',
          url: '/casinoconfig',
        },
        {
          title: '슬롯게임 관리',
          key: 'slot',
          url: '/slotconfig',
        },
      ],
    },
    {
      title: '사이트 이용관리',
      key: 'sites',
      icon: 'fa fa-gear',
      roles: ['admin', 'master'],
      children: [
        {
          title: '사이트 기본설정',
          key: 'siteSetting',
          url: '/siteconfig',
        },
        {
          title: '계좌번호 설정',
          key: 'accountSetting',
          url: '/bankaccount',
        },
        {
          title: '레벨별 혜택설정',
          key: 'levelSetting',
          url: '/levelconfig',
        },
      ],
    },

    {
      category: true,
      title: 'SuperAdmin',
      roles: ['master'],
    },
    {
      title: '슈퍼어드민',
      key: 'sites2',
      icon: 'fa fa-apple',
      roles: ['master'],
      children: [
        {
          title: '관리자머니 지급내역',
          key: 'adminmoneyhistory',
          url: '/adminmoneyhistory',
        },
      ]
    },
    
  ];
}

// export default connect(mapStateToProps)(getMenuData);
