import React from 'react';
import {
  Modal,
  Form,
  Select,
  Spin,
  Input,
  Button,
  notification,
  Switch,
} from 'antd';
import debounce from 'lodash/debounce';
import apiClient from 'services/axios';
import moment from 'moment';

const { Option } = Select;

class AutoCouponModal extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 10);
  }

  state = {
    data: [],
    value: [],
    fetching: false,
    name: '',
    amount: '',
    sending: false,
    couponName: '',
    nameSelect: 'block',
    nameInput: 'none',
  };

  componentDidMount() {
    this.getCouponNameList();
  }

  // 쿠폰명 목록 부르기
  getCouponNameList = () => {
    apiClient.get('/coupons/name/select?size=100').then(res => {
      this.setState({
        couponName: res.data.data,
      });
    });
  };

  selectChange = value => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };

  nameSelectChange = value => {
    this.setState({
      name: value,
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  fetchUser = value => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    apiClient.get(`/users/search?s=${value}`).then(body => {
      if (body.data.length > 0) {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        const data = body.data.map(user => ({
          text: `${user.iu_name} ${user.iu_username}`,
          value: user.iu_uuid,
          phone: user.iu_phone,
        }));
        this.setState({ data, fetching: false });
      } else {
        this.setState({ data: [], fetching: false });
      }
    });
  };

  onFinish = () => {
    const { name, amount, value, sending } = this.state;
    const { user, uuid, phone } = this.props;
    const dt = Date.parse(new Date());
    const to = dt + 7 * 24 * 60 * 60 * 1000;
    if (sending) {
      notification.warning({
        message: '쿠폰 발급중입니다.',
      });

      return;
    }
    if (user && !sending) {
      this.setState({
        sending: true,
      });
      apiClient
        .post('/coupons', {
          uuid,
          name,
          amount,
          validate_from: moment(dt).format('Y-MM-DD'),
          validate_to: moment(to).format('Y-MM-DD'),
          status: 0,
          is_auto: 1,
        })
        .then(res => {
          notification.success({
            message: res.data.message,
          });
          // cancel(target);
          apiClient.post('/sms/coupon/send', {
            phone,
            title: name,
          });
          setTimeout(() => window.location.reload(), 500);
        })
        .catch(e => {
          console.log(e);
        });

      setTimeout(() => {
        this.setState({
          sending: false,
        });
      }, 1000);
    }
    if (sending) {
      notification.warning({
        message: '쿠폰 발급중입니다.',
      });

      return;
    }
    this.setState({
      sending: true,
    });
    for (let i = 0; i < value.length; i += 1) {
      const keyArr = value[i].key.split('/');
      apiClient
        .post('/coupons/bulk', {
          uuid: keyArr[0],
          name,
          amount,
          validate_from: moment(dt).format('Y-MM-DD'),
          validate_to: moment(to).format('Y-MM-DD'),
          status: 0,
          is_auto: 1,
        })
        .then(res => {
          notification.success({
            message: res.data.message,
          });
          // cancel(target);
          apiClient.post('/sms/coupon/send', {
            phone: keyArr[1],
            title: name,
          });

          setTimeout(() => window.location.reload(), 500);
        })
        .catch(e => {
          console.log(e);
        });

      setTimeout(() => {
        this.setState({
          sending: false,
        });
      }, 1000);
    }
  };

  memoSwitch = value => {
    if (value === false) {
      this.setState({
        nameSelect: 'block',
        nameInput: 'none',
      });
    } else {
      this.setState({
        nameSelect: 'none',
        nameInput: 'block',
      });
    }
  };

  render() {
    const { visible, cancel, target, user } = this.props;
    const {
      fetching,
      data,
      value,
      couponName,
      name,
      nameSelect,
      nameInput,
    } = this.state;
    const xxx = true;
    if (couponName) {
      if (user) {
        return (
          <Modal
            destroyOnClose={xxx}
            title="회원 자동승인 쿠폰등록"
            visible={visible}
            width="400px"
            onCancel={() => cancel(target)}
            footer={[
              <Button key="back" onClick={() => cancel(target)}>
                취소
              </Button>,
              <Button
                key="submit"
                type="primary"
                form="createCoupon1"
                htmlType="submit"
              >
                발급
              </Button>,
            ]}
          >
            <Form layout="vertical" id="createCoupon1" onFinish={this.onFinish}>
              <Form.Item
                label="회원"
                name="search"
                initialValue={`${user}`}
                rules={[
                  {
                    required: true,
                    message: '회원을 선택해주세요.',
                  },
                ]}
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item label="쿠폰명 직접입력">
                <Switch onChange={this.memoSwitch} />
              </Form.Item>
              <Form.Item
                name="name"
                label="쿠폰명"
                rules={[
                  {
                    required: true,
                    message: '쿠폰명을 입력해주세요.',
                  },
                ]}
                style={{ width: '100%', display: nameSelect }}
              >
                <Select
                  name="name"
                  value={name}
                  placeholder="쿠폰명을 선택해주세요."
                  onChange={this.nameSelectChange}
                >
                  {couponName.map(d => (
                    <Option key={`${d.icn_name}`}>{d.icn_name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="name"
                label="쿠폰명"
                rules={[
                  {
                    required: true,
                    message: '쿠폰명을 입력해주세요.',
                  },
                ]}
                style={{ width: '100%', display: nameInput }}
              >
                <Input
                  name="name"
                  placeholder="쿠폰명"
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="amount"
                label="쿠폰금액"
                rules={[
                  {
                    required: true,
                    message: '쿠폰금액을 입력해주세요.',
                  },
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: '숫자만 입력해주세요.',
                  },
                ]}
              >
                <Input
                  name="amount"
                  addonAfter="원"
                  placeholder="쿠폰금액"
                  onChange={this.handleChange}
                />
              </Form.Item>
              {/* <Form.Item
              name="date"
              label="쿠폰 사용기간"
              rules={[
                {
                  required: true,
                  message: '사용기간을 선택해주세요.',
                },
              ]}
            >
              <RangePicker
                onChange={this.dateChange}
                style={{ width: '100%' }}
              />
            </Form.Item> */}
            </Form>
          </Modal>
        );
      }
      return (
        <Modal
          destroyOnClose={xxx}
          title="회원 자동승인 쿠폰등록"
          visible={visible}
          width="400px"
          onCancel={() => cancel(target)}
          footer={[
            <Button key="back" onClick={() => cancel(target)}>
              취소
            </Button>,
            <Button
              key="submit"
              type="primary"
              form="createCoupon"
              htmlType="submit"
            >
              발급
            </Button>,
          ]}
        >
          <Form layout="vertical" id="createCoupon" onFinish={this.onFinish}>
            <Form.Item
              label="회원검색"
              name="search"
              rules={[
                {
                  required: true,
                  message: '회원을 선택해주세요.',
                },
              ]}
            >
              <Select
                mode="multiple"
                labelInValue
                value={value}
                placeholder="회원을 검색해주세요. (두 글자 이상 입력해주세요.)"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.fetchUser}
                onChange={this.selectChange}
                style={{ width: '100%' }}
              >
                {data.map(d => (
                  <Option key={`${d.value}/${d.phone}`}>{d.text}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="쿠폰명 직접입력">
              <Switch onChange={this.memoSwitch} />
            </Form.Item>
            <Form.Item
              name="name"
              label="쿠폰명"
              rules={[
                {
                  required: true,
                  message: '쿠폰명을 입력해주세요.',
                },
              ]}
              style={{ width: '100%', display: nameSelect }}
            >
              <Select
                name="name"
                value={name}
                placeholder="쿠폰명을 선택해주세요."
                onChange={this.nameSelectChange}
              >
                {couponName.map(d => (
                  <Option key={`${d.icn_name}`}>{d.icn_name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="name"
              label="쿠폰명"
              rules={[
                {
                  required: true,
                  message: '쿠폰명을 입력해주세요.',
                },
              ]}
              style={{ width: '100%', display: nameInput }}
            >
              <Input
                name="name"
                placeholder="쿠폰명"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="amount"
              label="쿠폰금액"
              rules={[
                {
                  required: true,
                  message: '쿠폰금액을 입력해주세요.',
                },
                {
                  pattern: new RegExp(/^[0-9]*$/),
                  message: '숫자만 입력해주세요.',
                },
              ]}
            >
              <Input
                name="amount"
                addonAfter="원"
                placeholder="쿠폰금액"
                onChange={this.handleChange}
              />
            </Form.Item>
            {/* <Form.Item
            name="date"
            label="쿠폰 사용기간"
            rules={[
              {
                required: true,
                message: '사용기간을 선택해주세요.',
              },
            ]}
          >
            <RangePicker onChange={this.dateChange} style={{ width: '100%' }} />
          </Form.Item> */}
          </Form>
        </Modal>
      );
    }
    return <></>;
  }
}

export default AutoCouponModal;
