import apiClient from "services/axios";
import store from "store";

export async function login(username, password) {
  return apiClient
    .post("/auth/agent/login", {
      username,
      password,
    })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data;
        if (accessToken) {
          store.set("accessToken", accessToken);
          if (!store.get("allSound")) {
            store.set("allSound", true);
          }
          if (!store.get("regSound")) {
            store.set("regSound", true);
          }
          if (!store.get("depositSound")) {
            store.set("depositSound", true);
          }
          if (!store.get("withdrawSound")) {
            store.set("withdrawSound", true);
          }
          if (!store.get("couponSound")) {
            store.set("couponSound", true);
          }
        }
        return response.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}

export async function register(email, password, name) {
  return apiClient
    .post("/auth/register", {
      email,
      password,
      name,
    })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data;
        if (accessToken) {
          store.set("accessToken", accessToken);
        }
        return response.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}

export async function currentAccount() {
  if (store.get("accessToken") === undefined) {
    return false;
  }
  return apiClient
    .get("/auth/agent")
    .then((response) => {
      if (response) {
        const { accessToken, username } = response.data;
        if (accessToken) {
          store.set("accessToken", accessToken);
          store.set("username", username);
        }
        return response.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}

export async function logout() {
  store.remove("accessToken");
  return true;
  // return apiClient
  //   .get('/auth/logout')
  //   .then(() => {
  //   })
  //   .catch(err => console.log(err));
}

export async function userRegister(
  username,
  passwoed,
  partner,
  isTest,
  level,
  status,
  name,
  nickname,
  phone,
  bank,
  backAccount,
  isCustomComp,
  customCompRate,
  isCustomLoosingCoupon,
  customLoosingCouponRate,
  isCustomActivityCoupon,
  customActivityCouponRate
) {
  return apiClient
    .post("/users", {
      username,
      passwoed,
      agent: partner,
      is_test: isTest,
      level,
      status,
      name,
      nickname,
      phone,
      bank,
      back_account: backAccount,
      is_custom_comp: isCustomComp,
      custom_comp_rate: customCompRate,
      is_custom_loosing_coupon: isCustomLoosingCoupon,
      custom_loosing_coupon_rate: customLoosingCouponRate,
      is_custom_activity_coupon_custom: isCustomActivityCoupon,
      custom_activity_cupon_rate: customActivityCouponRate,
    })
    .then((response) => {
      if (response) {
        return true;
      }
      return false;
    })
    .catch((err) => console.log(err));
}
