import React from 'react';
import CouponModal from 'components/couponModal';
import AutoCouponModal from 'components/autoCouponModal';
import NoticeModal from 'components/noticeModal';
import NoticeAutoModal from 'components/noticeautoModal';
import DomainModal from 'components/domainModal';
import ParnterDepositModal from 'components/parnterDepositModal';
import ParnterWithdrawalModal from 'components/parnterWithdrawalModal';

class ModalStation extends React.Component {
  render() {
    const { visible, cancel, target } = this.props;
    if (target === 'coupon_modal') {
      return <CouponModal visible={visible} target={target} cancel={cancel} />;
    }

    if (target === 'auto_coupon_modal') {
      return (
        <AutoCouponModal visible={visible} target={target} cancel={cancel} />
      );
    }

    if (target === 'notice_modal') {
      return <NoticeModal visible={visible} target={target} cancel={cancel} />;
    }

    if (target === 'noticeauto_modal') {
      return <NoticeAutoModal visible={visible} target={target} cancel={cancel} />;
    }

    if (target === 'domain_modal') {
      return <DomainModal visible={visible} target={target} cancel={cancel} />;
    }

    if (target === 'partnerDeposit_modal') {
      return (
        <ParnterDepositModal
          visible={visible}
          target={target}
          cancel={cancel}
        />
      );
    }

    if (target === 'partnerWithdrawal_modal') {
      return (
        <ParnterWithdrawalModal
          visible={visible}
          target={target}
          cancel={cancel}
        />
      );
    }

    return <NoticeModal visible={visible} target={target} cancel={cancel} />;
  }
}

export default ModalStation;
