import React from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  notification,
  Select,
  Spin,
  Tabs,
  Skeleton,
  Popconfirm,
  message,
} from "antd";
import apiClient from "services/axios";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import debounce from 'lodash/debounce';

const { Option } = Select;
const { TabPane } = Tabs;

class NoticeAutoModal extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 10);
  }

  state = {
    data: [],
    value: [],
    fetching: false,
    loaded: false,
    notificationTemp: [],
    editorStateAuto: EditorState.createEmpty(),
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    this.getCouponNameList();
  }

  // 쿠폰명 목록 부르기
  getCouponNameList = () => {
    apiClient.get("notificationTemp?page=1&size=100&status=1").then((res) => {
      this.setState({
        notificationTemp: res.data,
        loaded: true,
      });
    });
  };

  

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  selectChange = (value) => {
    const content = value.split("%.%");
    this.setState({
      editorStateAuto: EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(content[0]))
      ),
      titleAuto: content[1],
    });
  };

  selectChange2 = value => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };

  // selectBankChange = value => {
  //   this.setState({
  //     bank: value,
  //   });
  // };

  fetchUser = value => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    apiClient.get(`/users/search?s=${value}`).then(body => {
      if (body.data.length > 0) {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        const data = body.data.map(user => ({
          text: `${user.iu_name} ${user.iu_username}`,
          value: user.iu_uuid,
        }));
        this.setState({ data, fetching: false });
      } else {
        this.setState({ data: [], fetching: false });
      }
    });
  };


  sendAutoAcc = () => {
    const { cancel, target } = this.props;
    const { editorStateAuto, titleAuto, value } = this.state;

    for (let i = 0; i < value.length; i += 1) {
    apiClient.post("/notifications", {
        uuid: value[i].key,
        title: titleAuto,
        content: draftToHtml(convertToRaw(editorStateAuto.getCurrentContent()))
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">")
          .replaceAll("&amp;nbsp;", " "),
        status: 0,
      })
      .then((res) => {
        notification.success({
          message: res.data.message,
        });
        cancel(target);
      })
      .catch((e) => {
        console.log(e);
      });
    }
  };

  sendAcc = () => {
    const { cancel, target } = this.props;
    const { editorState, title, value } = this.state;



    for (let i = 0; i < value.length; i += 1) {
    apiClient
      .post("/notifications", {
        uuid: value[i].key,
        title,
        content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">")
          .replaceAll("&amp;nbsp;", " "),
        status: 0,
      })
      .then((res) => {
        notification.success({
          message: res.data.message,
        });
        cancel(target);
      })
      .catch((e) => {
        console.log(e);
      });
    }
  };

  sendCancel = () => {
    message.error("취소되었습니다.");
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onEditorStateAutoChange = (editorState) => {
    this.setState({
      editorStateAuto: editorState,
    });
  };


  render() {
    const { visible, cancel, target} = this.props;
    const {
      loaded,
      notificationTemp,
      editorStateAuto,
      editorState,
      titleAuto,
      value,
      fetching,
      data,
    } = this.state;
    const xxx = true;
    const yyy = false;
    console.log(this.state);
    return (
      <Modal
        destroyOnClose={xxx}
        title="N 알림전송"
        visible={visible}
        width="400px"
        onCancel={() => cancel(target)}
        footer={yyy}
      >
        <Form layout="vertical" id="sendAccount">
          <Form.Item
            label="회원검색"
            name="search"
            rules={[
              {
                required: true,
                message: '회원을 선택해주세요.',
              },
            ]}
          >
            <Select
              mode="multiple"
              labelInValue
              value={value}
              placeholder="회원을 검색해주세요. (두 글자 이상 입력해주세요.)"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={this.fetchUser}
              onChange={this.selectChange2}
              style={{ width: '100%' }}
            >
              {data.map(d => (
                <Option key={d.value}>{d.text}</Option>
              ))}
            </Select>
          </Form.Item>
            
          {loaded ? (
            <Tabs defaultActiveKey="1">
              <TabPane tab="목록에서 선택" key="11">
                <Form.Item
                  label="템플릿"
                  name="accountInfo"
                  rules={[
                    {
                      required: true,
                      message: "계좌를 선택해주세요.",
                    },
                  ]}
                >
                  <Select placeholder="템플릿" onChange={this.selectChange}>
                    {notificationTemp.data.map((e) => {
                      return (
                        <Option
                          key={e.inft_idx}
                          value={`${e.inft_content}%.%${e.inft_noti_title}`}
                        >
                          {e.inft_title}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="제목">
                  <Input
                    name="titleAuto"
                    placeholder="제목"
                    onChange={this.handleChange}
                    value={titleAuto}
                  />
                </Form.Item>
                <Form.Item
                  name="accNumber"
                  label="안내문구"
                  rules={[
                    {
                      required: true,
                      message: "안내문구를 입력해주세요.",
                    },
                  ]}
                >
                  <Editor
                    editorState={editorStateAuto}
                    editorClassName="px-3 border border-gray-1"
                    editorStyle={{
                      height: 250,
                      overflow: "auto",
                    }}
                    onEditorStateChange={this.onEditorStateAutoChange}
                  />
                </Form.Item>
                <div className="text-right">
                  <Button key="back" onClick={() => cancel(target)}>
                    취소
                  </Button>
                  <Popconfirm
                    title="정말 전송하시겠습니까?"
                    onConfirm={() => this.sendAutoAcc()}
                    onCancel={this.sendCancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      key="submit"
                      type="primary"
                      form="sendAccount"
                      htmlType="submit"
                      className="ml-3"
                    >
                      전송
                    </Button>
                  </Popconfirm>
                </div>
              </TabPane>
              <TabPane tab="직접입력" key="12">
                <Form.Item
                  label="제목"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "제목을 입력해주세요.",
                    },
                  ]}
                >
                  <Input
                    name="title"
                    placeholder="제목"
                    onChange={this.handleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="accNumber"
                  label="안내문구"
                  rules={[
                    {
                      required: true,
                      message: "안내문구를 입력해주세요.",
                    },
                  ]}
                >
                  <Editor
                    editorState={editorState}
                    editorClassName="px-3 border border-gray-1"
                    editorStyle={{
                      height: 250,
                      overflow: "auto",
                    }}
                    onEditorStateChange={this.onEditorStateChange}
                  />
                </Form.Item>
                <div className="text-right">
                  <Button key="back" onClick={() => cancel(target)}>
                    취소
                  </Button>
                  <Popconfirm
                    title="정말 전송하시겠습니까?"
                    onConfirm={() => this.sendAcc()}
                    onCancel={this.sendCancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      key="submit"
                      type="primary"
                      form="sendAccount"
                      htmlType="submit"
                      className="ml-3"
                    >
                      전송
                    </Button>
                  </Popconfirm>
                </div>
              </TabPane>
            </Tabs>
          ) : (
            <Skeleton />
          )}
        </Form>
      </Modal>
    );
  }
}

export default NoticeAutoModal;
