import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Button, notification, Select } from 'antd';
import apiClient from 'services/axios';
import commaNumber from 'comma-number';
import { Bank } from 'components/data/bank';

const { Option } = Select;

const mapStateToProps = ({ user }) => ({
  user,
  role: user.role,
  step: user.step,
  id: user.id,
});

class PartnerWithdrawalModal extends React.Component {
  state = {
    rate: '',
    calcBalance: '',
    withdrawalAmount: '',
  };

  componentDidMount() {
    this.getInfo();
  }

  getInfo = () => {
    const { user } = this.props;
    apiClient.get(`/agents/${user.agent}`).then(res => {
      this.setState({
        rate: res.data.info.ia_coupon_rate,
        calcBalance: res.data.info.ia_calc_balance,
      });
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // 은행 변경
  bankChange = value => {
    this.setState({
      bank: value,
    });
  };

  onFinish = () => {
    const { withdrawalAmount, bank, account, name } = this.state;
    const { cancel, target } = this.props;
    apiClient
      .post('/transactions/agent/request/withdrawal', {
        amount: Number(withdrawalAmount),
        name,
        bank,
        bankAccount: account,
      })
      .then(res => {
        notification.success({
          message: res.data.message,
        });
        cancel(target);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const { visible, cancel, target } = this.props;
    const { rate, calcBalance } = this.state;
    const xxx = true;
    return (
      <Modal
        destroyOnClose={xxx}
        title="출금신청"
        visible={visible}
        width="400px"
        onCancel={() => cancel(target)}
        footer={[
          <Button key="back" onClick={() => cancel(target)}>
            취소
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="requestDeposit"
            htmlType="submit"
          >
            신청
          </Button>,
        ]}
      >
        <Form layout="vertical" id="requestDeposit" onFinish={this.onFinish}>
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                name="balance"
                label="출금가능금액"
                initialValue={commaNumber(calcBalance)}
              >
                <Input addonAfter="원" readOnly />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item name="rate" label="쿠폰비율" initialValue={rate}>
                <Input addonAfter="%" readOnly />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="name"
            label="예금주"
            rules={[
              {
                required: true,
                message: '예금주를 입력해주세요.',
              },
            ]}
          >
            <Input name="name" onChange={e => this.handleChange(e)} />
          </Form.Item>
          <Form.Item
            name="bank"
            label="은행"
            rules={[
              {
                required: true,
                message: '은행을 선택해주세요.',
              },
            ]}
          >
            <Select placeholder="은행" onChange={this.bankChange}>
              {Bank.banks &&
                Bank.banks.map(e => {
                  return (
                    <Option key={e.Value} value={e.Value}>
                      {e.Value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="account"
            label="계좌번호"
            rules={[
              {
                required: true,
                message: '계좌번호를 입력해주세요.',
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: '숫자만 입력해주세요.',
              },
            ]}
          >
            <Input name="account" onChange={e => this.handleChange(e)} />
          </Form.Item>
          <Form.Item
            name="withdrawalAmount"
            label="출금금액"
            rules={[
              {
                required: true,
                message: '출금금액을 입력해주세요.',
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: '숫자만 입력해주세요.',
              },
            ]}
          >
            <Input
              name="withdrawalAmount"
              onChange={e => this.handleChange(e)}
              addonAfter="원"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(PartnerWithdrawalModal);
