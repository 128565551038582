import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  notification,
  TreeSelect,
} from 'antd';
import apiClient from 'services/axios';

const { Option } = Select;

const mapStateToProps = ({ user }) => ({
  user,
  role: user.role,
  step: user.step,
});

class DomainModal extends React.Component {
  state = {
    domain: '',
    usage: '',
    status: '',
    partner: '',
    partnerTree: '',
  };

  componentDidMount() {
    this.getPartnerTree();
  }

  // 파트너목록 부르기
  getPartnerTree = () => {
    apiClient.get('/agents/tree/basic').then(res => {
      this.setState({
        partnerTree: [res.data[0]],
      });
    });
  };

  // 파트너 셀렉트
  partnerChange = value => {
    this.setState({ partner: value });
  };

  statusSelectChange = value => {
    this.setState({
      status: value,
    });
    console.log(this.state);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onFinish = () => {
    const { domain, status, usage, partner } = this.state;
    const { cancel, target } = this.props;
    apiClient
      .post('/domains', {
        domain,
        usage,
        status: Number(status),
        agent: partner,
      })
      .then(res => {
        notification.success({
          message: res.data.message,
        });
        cancel(target);
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const { partner, partnerTree } = this.state;
    const { visible, cancel, target } = this.props;
    const xxx = true;
    const { step } = this.props;
    return (
      <Modal
        destroyOnClose={xxx}
        title="도메인 신청"
        visible={visible}
        width="400px"
        onCancel={() => cancel(target)}
        footer={[
          <Button key="back" onClick={() => cancel(target)}>
            취소
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="createCoupon"
            htmlType="submit"
          >
            신청
          </Button>,
        ]}
      >
        <Form layout="vertical" id="createCoupon" onFinish={this.onFinish}>
          <Form.Item
            name="domain"
            label="도메인명"
            rules={[
              {
                required: true,
                message: '도메인을 입력해주세요.',
              },
            ]}
          >
            <Input
              name="domain"
              placeholder="http:// 생략, ex) naver.com"
              onChange={this.handleChange}
            />
          </Form.Item>
          <Form.Item
            name="usage"
            label="사용용도"
            rules={[
              {
                required: true,
                message: '사용용도를 입력해주세요.',
              },
            ]}
          >
            <Input
              name="usage"
              placeholder="사용용도"
              onChange={this.handleChange}
            />
          </Form.Item>
          <Form.Item
            name="agent"
            label="파트너"
            rules={[
              {
                required: true,
                message: '파트너를 선택해주세요.',
              },
            ]}
          >
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              treeData={partnerTree}
              value={partner}
              treeNodeFilterProp="title"
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="파트너를 선택해주세요"
              allowClear
              treeDefaultExpandAll
              onChange={this.partnerChange}
            />
          </Form.Item>
          {step < 3 ? (
            <Form.Item
              name="status"
              label="상태"
              rules={[
                {
                  required: true,
                  message: '상태를 선택해주세요.',
                },
              ]}
            >
              <Select
                defaultValue="상태"
                style={{ width: '100%' }}
                className="mr-3 mb-3"
                onChange={this.statusSelectChange}
              >
                <Option value="disabled" disabled>
                  상태
                </Option>
                <Option value="2">정상</Option>
                <Option value="3">정지</Option>
              </Select>
            </Form.Item>
          ) : (
            ''
          )}
        </Form>
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(DomainModal);
